import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const maskClasses = {
  D: "Diamond",
  G: "Gold",
};
const user = getLoggedUser();

async function createMask(payload) {
  return fetch(apiUrl + "masks", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllMasks() {
  return fetch(apiUrl + "masks", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getMask(id) {
  return fetch(apiUrl + "masks/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateMask(payload, id) {
  return fetch(apiUrl + "masks/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteMask(id) {
  return fetch(apiUrl + "masks/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createMask,
  getAllMasks,
  getMask,
  updateMask,
  deleteMask,
  maskClasses,
};
