import { apiUrl } from "../constants/global";

async function loginUser(credentials) {
  return fetch(apiUrl + "login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

async function otpLogin(credentials) {
  return fetch(apiUrl + "send/otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

async function validateOtp(credentials) {
  return fetch(apiUrl + "validate/otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

async function forgotPassword(payload) {
  return fetch(apiUrl + "forgot_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function checkValidResetUrl(payload) {
  return fetch(apiUrl + "reset_url_check", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function resetPassword(payload) {
  return fetch(apiUrl + "reset_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export {
  loginUser,
  otpLogin,
  validateOtp,
  forgotPassword,
  checkValidResetUrl,
  resetPassword,
};
