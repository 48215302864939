import { apiUrl } from "../constants/global";

async function submitContact(payload) {
  return fetch(apiUrl + "contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export default submitContact;
